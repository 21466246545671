<template>
  <div id="app" :class="{ 'has-mouse': hasMouse }" @touchstart="hasMouse = false">   
     
    <Flipbook class="flipbook myDivToPrint" :pages="pages" :pagesHiRes="pagesHiRes" :startPage="pageNum" v-slot="flipbook" ref="flipbook" @flip-left-end="onFlipLeftEnd" @flip-right-end="onFlipRightEnd">
      <div class="action-bar">
        <Slide width="350" :closeOnNavigation="true">
          <span class="titulos">Edición 45º Aniversario</span>
          <span v-for="suple in suplementos" :key="suple[0]"><a :href="'#'+suple[2]"><img alt="Suplementos" width="90%" :src="suple[1]" /></a></span>
        </Slide>
        <div class="logo" v-on:click="AmbitoCom"></div>
        <div class="edicion" style="color: #fff;">{{edicion_msg}}</div>
        <left-icon title="Página anterior" class="btn left" :class="{ disabled: !flipbook.canFlipLeft }" @click="flipbook.flipLeft" />
        <plus-icon title="Zoom acercar" class="btn plus" :class="{ disabled: !flipbook.canZoomIn }" @click="flipbook.zoomIn" />
        <minus-icon title="Zoom alejar" class="btn minus" :class="{ disabled: !flipbook.canZoomOut }" @click="flipbook.zoomOut" />
        <right-icon title="Página siguiente" class="btn right" :class="{ disabled: !flipbook.canFlipRight }" @click="flipbook.flipRight" />
        <File-Pdf-Box-Outline class="btn " v-on:click="DescargaPdf" title="Descarga de PDF" />
        <Printer title="Imprimir" class="btn " v-on:click="ImprimePdf" />
        <div class="range">
        <div class="sliderValue">
          <span class="nro_pagina"></span>
        </div>
        <div class="field">
          <div class="value left">1</div>
          <input type="range" min="1" :max="pages.length-1" value="1" steps="1">
          <div class="value right">{{pages.length-1}}</div>
        </div>
      </div>
      </div>
    
      <div class="edicion-m">{{edicion_msg}}</div>      
    </Flipbook>
        
  
  
  </div>
</template>
<script>
     
 
  import 'vue-material-design-icons/styles.css'
  import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle'
  import RightIcon from 'vue-material-design-icons/ChevronRightCircle'
  import PlusIcon from 'vue-material-design-icons/PlusCircle'
  import MinusIcon from 'vue-material-design-icons/MinusCircle'
  import FilePdfBoxOutline from 'vue-material-design-icons/FilePdfBoxOutline'
  import Printer from 'vue-material-design-icons/Printer'
  import Flipbook from 'flipbook-vue'
  
  import {
    Slide
  } from 'vue-burger-menu'
  import axios from "axios";

  export default {
    components: {
      Flipbook,
      LeftIcon,
      RightIcon,
      PlusIcon,
      MinusIcon,
      FilePdfBoxOutline,
      Printer,
      Slide
    },
    name: 'AmbitoFlip',
    

    data() {
      

      return {
        
        pages: [],
        pagesHiRes: [],
        hasMouse: true,
        pageNum: 1,
        fecha: '',
        suplementos: [],
        usuarios: [],  
    

      }
      
    },



    created() {

 
    

      var fecha;
      var fecha_url;



      fecha_url = this.$route.params.fecha;      

    
    




      
      if (fecha_url === undefined) {
        fecha = new Date();
        fecha_url = fecha.getTime()/1000;        
      }else{
        fecha = new Date(fecha_url * 1000);     
      }
       

      let dias = [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
      let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  

      var dateOffset;
     


      if(dias[fecha.getDay()]=="Sábado"){        
        dateOffset = 86400;    
        fecha_url = (fecha_url - dateOffset);        
        fecha = new Date(fecha_url * 1000);          
      }else{
        if(dias[fecha.getDay()]=="Domingo"){          
          dateOffset = 172800;    
          fecha_url = (fecha_url - dateOffset);          
          fecha = new Date(fecha_url * 1000);  
          
        }       
      }

      


      
      var dia = fecha.getDate();
      var mes = fecha.getMonth() + 1;
      var ano = fecha.getFullYear();

      if (dia < 10) {
        dia = '0' + dia;
      }
      if (mes < 10) {
        mes = '0' + mes;
      }

      this.dia = dia;
      this.mes = mes;
      this.ano = ano;

      

      

      var edicion_msg = dias[fecha.getDay()] + " " + fecha.getDate() + " de " + meses[fecha.getMonth()] + " de " + fecha.getFullYear();

      this.edicion_msg = edicion_msg;

      document.title = "Anuario 2023 | ámbito.com";
    },
    methods: {









      
      
      ImprimePdf() {
        print();

      
      },
      AmbitoCom(){        
        window.open('https://ambito.com', '_blank');
      },
      DescargaPdf() {

        var url = 'https://media1.ambito.com/impresa-digital/2023/anuario/ambito_financiero-anuario.pdf';

        fetch(url).then(function(t) {
          return t.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", "anuario_ambito_financiero.pdf");
            a.click();
          });
        });

     

       
      },
      onFlipLeftEnd(page) {   
         const slideValue = document.querySelector("span.nro_pagina");
    
        slideValue.classList.remove("show");
      
        return window.location.hash = '#' + page;
       
      },  
      onFlipRightEnd(page) {        
        const slideValue = document.querySelector("span.nro_pagina");
    
        slideValue.classList.remove("show");
        return window.location.hash = '#' + page;
      },      
      setPageFromHash() {
        const n = parseInt(window.location.hash.slice(1), 10);
        if (isFinite(n)) {
          
          const inputSlider = document.querySelector("input");
          const slideValue = document.querySelector("span.nro_pagina");
          
          slideValue.style.left = (n*100/this.pages.length-1) + "%";
          inputSlider.value = n;
          return this.pageNum = n; 
          
        }
      }
    },
    async mounted() {


    const slideValue = document.querySelector("span.nro_pagina");
      const inputSlider = document.querySelector("input");
       
      inputSlider.oninput = (()=>{
        
        let value = inputSlider.value;
        slideValue.textContent = value;
        slideValue.style.left = (value*100/this.pages.length-1) + "%";
        slideValue.classList.add("show");
        window.location.hash = '#' + value;
        console.log(slideValue);
      });

      inputSlider.onblur = (()=>{
        slideValue.classList.remove("show");
      });
 



   
      try {
        const res = await axios.get(
          "https://media1.ambito.com/impresa-digital/2023/anuario/baja/paginas_baja.json?id=" +  Math.floor(Date.now() / 1000)
        );

        this.pages = res.data.paginas;
      } catch (e) {
        console.error(e);
      }


      try {
        const res = await axios.get(
          "https://media1.ambito.com/impresa-digital/2023/anuario/alta/paginas_alta.json?id=" +  Math.floor(Date.now() / 1000)
        );

        this.pagesHiRes = res.data.paginas;
      } catch (e) {
        console.error(e);
      }

      try {
        const res = await axios.get("https://media1.ambito.com/impresa-digital/2023/anuario/baja/suplementos.json?id=" +  Math.floor(Date.now() / 1000));
        this.suplementos = res.data.paginas;

      } catch (e) {
        console.error(e)
      }



      window.addEventListener('keydown', ev => {
        const {
          flipbook
        } = this.$refs;
        if (!flipbook) {
          return;
        }
        if ((ev.keyCode === 37) && flipbook.canFlipLeft) {
          flipbook.flipLeft();
        }
        if ((ev.keyCode === 39) && flipbook.canFlipRight) {
          return flipbook.flipRight();
        }
        if ((ev.keyCode === 107) && flipbook.canZoomIn) {
          return flipbook.zoomIn();
        }
        if ((ev.keyCode === 109) && flipbook.canZoomOut) {
          return flipbook.zoomOut();
        }
      });


      window.addEventListener('hashchange', this.setPageFromHash);
      return this.setPageFromHash();
      
    }


  }
</script>
<style>

  @media print {
    .flipbook {
      width: 139vw !important;
    }

  }

  @media only screen and (max-width: 600px) {
    .range{
      width: 40px!important;     
    }
    .viewport{
          margin-top: -40px;
    }
    .logo {
      padding-right: 0px!important;
      background-image: url("/img/logo-ambito-m.svg") !important;
      background-repeat: no-repeat;
      background-size: contain;
      width: 19px !important;
      height: 50px;
      margin-left: 40px;
      cursor: pointer;
    }

    .page-num {
      font-size: 10px !important;
    }

    .action-bar .btn:not(:first-child) {
      margin-left: 7px !important;
    }

    .action-bar .btn {
      font-size: 25px !important;
      color: #999;
    }

    .edicion {
      display: none;
    }

    .edicion-m {
      display: block !important;
      text-align: center;
      padding-bottom: 15px;
    }


  }

  .logo {
    background-image: url(/img/logo-ambito.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    padding-bottom: 6px;
    padding-right: 19px;
    height: 23px;
    cursor: pointer;
  }

  .edicion {
    padding-top: 3px;
    color: #cbccc2;
  }

  .edicion-m {
    display: none;
  }

  .page-num {
    font-size: 20px;

  }

  .bm-burger-button {
    position: fixed;
    width: 30px !important;
    height: 24px !important;
    left: 20px !important;
    top: 13px !important;
    cursor: pointer;
  }

  .bm-burger-bars {
    background-color: #999999 !important;
  }

  .bm-burger-bars:hover {
    background-color: #ccc !important;
  }

  .titulos {
    color: white !important;
    text-decoration: none;
  }

  .flipbook {
    width: 90vw;
    height: 90vh;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    color: #ccc;
    overflow: hidden;
  }

  a {
    color: inherit;
  }

  .action-bar {
    width: 100%;
    height: 30px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .action-bar .btn {
    font-size: 30px;
    color: #999;
  }

  .action-bar .btn svg {
    bottom: 0;
  }

  .action-bar .btn:not(:first-child) {
    margin-left: 10px;
  }

  .has-mouse .action-bar .btn:hover {
    color: #ccc;
    filter: drop-shadow(1px 1px 5px #000);
    cursor: pointer;
  }

  .action-bar .btn:active {
    filter: none !important;
  }

  .action-bar .btn.disabled {
    color: #666;
    pointer-events: none;
  }

  .action-bar .page-num {
    font-size: 15px;
    margin-left: 5px;
    padding-top: 6px;
  }

  .flipbook .viewport {
    width: 90vw;
    height: calc(100vh - 50px - 40px);
  }

  .flipbook .bounding-box {
    box-shadow: 0 0 20px #000;
  }

  .credit {
    font-size: 12px;
    line-height: 20px;
    margin: 10px;
  }






















*{
  margin: 0;
  padding: 0;

  font-family: 'Poppins', sans-serif;
  text-align: center;
  place-items: center;
}


.range{
  position: relative;
  color:#fff;
  width: 100px; 
  padding-left: 20px;
  padding-top: 2.5px;
  z-index: 0;

}
.sliderValue{
  position: relative;
  width: 100%;
}
.sliderValue span{
  position: absolute;
  height: 45px;
  width: 45px;
  transform: translateX(-70%) scale(0);
  font-weight: 500;
  top: -68px;
  margin-left: 13px;
  line-height: 55px;
 
  color: #fff;
  transform-origin: bottom;
  transition: transform 0.3s ease-in-out;
}


.field{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}
.field .value{
  position: absolute;
  font-size: 16px;
  color: #cbccc2;
  font-weight: 200;
}
.field .value.left{
  left: -14px;
}
.field .value.right{
  right: -20px;
}
.range input{
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: #999999;
  border-radius: 5px;
  outline: none;
  border: none;
  
}
.range input::-webkit-slider-thumb{
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  background: #999999;
  border-radius: 50%;
  background: #999999;
  
  cursor: pointer;
}
.range input::-moz-range-thumb{
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  background: #0087cc;
  border: 1px solid #0087cc;
  cursor: pointer;
}
.range input::-moz-range-progress{
  background: #0087cc; 
}
</style>